<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-27 18:03:02
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\Dashboard.vue
-->
<template>
  <div class="container">
    <a-row class="row">
      <a-col :span="24">
        <div class="welcome">
          <div class="left">
            <a-avatar
              style="
                color: #f56a00;
                background-color: #fde3cf;
                border-radius: 5px;
              "
              shape="square"
              :size="60"
              >{{ userInfo.realname.substr(0, 1) }}</a-avatar
            >
            <span class="info"
              >欢迎您， <a href="javascript:;">{{ userInfo.realname }}</a></span
            >
          </div>
          <div class="right">
            企业名称： {{ tenantInfo ? tenantInfo.name : "-" }}
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row class="row" :gutter="20">
      <a-col :span="12">
        <div class="statistic">
          <a-carousel effect="fade">
            <div><h3>1</h3></div>
            <div><h3>2</h3></div>
            <div><h3>3</h3></div>
            <div><h3>4</h3></div>
          </a-carousel>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="statistic"></div>
      </a-col>
      <a-col :span="6">
        <div class="statistic"></div>
      </a-col>
    </a-row>
    <!-- <a-row class="row" :gutter="20">
      <a-col :span="12">
        <div class="chart"></div>
      </a-col>
      <a-col :span="12">
        <div class="chart"></div>
      </a-col>
    </a-row> -->
    <a-row class="row">
      <a-col :span="24">
        <div class="form">
          <a-calendar />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Dashboard",
  computed: {
    ...mapGetters(["userInfo", "tenantInfo"]),
  },
};
</script>

<style lang="less" scoped>
.ant-carousel /deep/ .slick-slide {
  text-align: center;
  height: 200px;
  line-height: 200px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel /deep/ .slick-slide h3 {
  color: #fff;
}
.container {
  .row {
    margin-bottom: 20px;
    .welcome {
      height: 100px;
      padding: 20px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      .info {
        margin-left: 20px;
        vertical-align: bottom;
      }
    }
    .statistic {
      height: 200px;
      background: #fff;
    }
    .chart {
      height: 400px;
      background: #fff;
    }
    .form {
      height: 820px;
      background: #fff;
    }
  }
}
</style>
